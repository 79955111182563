import styled from "styled-components"

const HomeMediaStyled = styled.div`
  padding-top: 170px;
  padding-bottom: 100px;
  @media (max-width: 1280px) {
    padding-top: 120px;
  }
  .container {
    /* display: flex;
    justify-content: space-between;
    flex-wrap: wrap; */
    @media (max-width: 880px) {
      max-width: 100%;
      padding: 0;
    }
  }
  .rotator__wrapper {
    overflow: hidden;
  }
`

export default HomeMediaStyled
