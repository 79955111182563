import styled from "styled-components"

const HomeFeedStyled = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  h2 {
    width: 100%;
    margin-bottom: 100px;
    @media (max-width: 880px) {
      margin-bottom: 50px;
    }
  }
  .blog__expert {
    max-width: 390px;
    @media (max-width: 1280px) {
      max-width: 310px;
    }
    @media (max-width: 1080px) {
      order: 4;
      max-width: 100%;
      width: 100%;
    }
    &__heading {
      padding-bottom: 50px;
      font-size: 20px;
      font-weight: 500;
      @media (max-width: 1080px) {
        width: 100%;
        padding-top: 50px;
        text-align: center;
      }
      @media (max-width: 880px) {
        padding-bottom: 30px;
      }
    }
    &__wrapper {
      @media (max-width: 1080px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      @media (max-width: 1080px) {
        width: 50%;
      }
      @media (max-width: 880px) {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    &__img {
      width: 100px;
      margin-right: 25px;
    }
    &__descirption {
      p {
        font-size: 19px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  p.blog__expert__descirption__position {
    font-weight: 300;
    font-size: 17px;
    margin: 0;
  }
  .blog__feed {
    max-width: 670px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1280px) {
      max-width: 610px;
    }
    @media (max-width: 1080px) {
      order: 2;
      max-width: 100%;
    }
    @media (max-width: 880px) {
      flex-wrap: wrap;
    }
    &--2 {
      & > a,
      & > div {
        max-width: 320px;
        width: calc(50% - 15px);
        @media (max-width: 880px) {
          width: 100%;
          max-width: initial;
          padding-bottom: 30px;
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .feed__box__title {
      padding-bottom: 40px;
    }
    .feed__box {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .feed__box__button {
      margin-top: auto;
    }
  }
`

export default HomeFeedStyled
