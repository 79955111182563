import React, { useState } from "react"
import myInitObject from "../ultis/variable"
import Link from "gatsby-link"
import ButtonStyled from "./styles/ButtonStyles"
import CaseStudyListStyled from "./styles/CaseStudyListStyles"
import VisibilitySensor from "react-visibility-sensor"

const CaseStudyList = ({
  page,
  number,
  heading,
  description,
  promotetText,
  linkText,
  link,
  backroundPaht,
}) => {
  const [isVisible, setVisibility] = useState(false)
  const onChange = visiblity => {
    if (!!visiblity) setVisibility(visiblity)
  }
  const topStartPosition =
    typeof window !== `undefined` ? (window.innerHeight / 3) * 2 : null
  return (
    <VisibilitySensor
      partialVisibility
      offset={{ top: topStartPosition }}
      onChange={onChange}
    >
      <CaseStudyListStyled>
        <div
          className={`caseStudyList__item caseStudyList__item--${
            number % 2 ? "even" : "odd"
          } ${isVisible ? "caseStudyList__item--showed" : ""}`}
        >
          {number % 2 ? (
            <>
              <div className="container">
                <div className="caseStudyList__half">
                  <p className="caseStudyList__number">
                    <span>0{number + 1}.</span>
                  </p>
                  {heading ? (
                    <p className="caseStudyList__heading caseStudyList__heading--underline">
                      {heading}
                    </p>
                  ) : null}
                  <p className="caseStudyList__description">{description}</p>
                  {promotetText ? (
                    <p className="caseStudyList__heading">{promotetText}</p>
                  ) : null}
                  {link ? (
                    <ButtonStyled dark={true}>
                      <Link to={link}>{linkText}</Link>
                    </ButtonStyled>
                  ) : null}
                </div>
                <div className="tmp"></div>
              </div>
              <div className="caseStudyList__img">
                <img
                  src={myInitObject.cockpitUrl + backroundPaht}
                  alt={description}
                />
              </div>
            </>
          ) : (
            <>
              <div className="caseStudyList__img">
                <img
                  src={myInitObject.cockpitUrl + backroundPaht}
                  alt={description}
                />
              </div>
              <div className="container">
                <div className="tmp"></div>
                <div className="caseStudyList__half">
                  <p className="caseStudyList__number">
                    <span>0{number + 1}.</span>
                  </p>
                  {heading ? (
                    <p className="caseStudyList__heading caseStudyList__heading--underline">
                      {heading}
                    </p>
                  ) : null}
                  <p className="caseStudyList__description">{description}</p>
                  {promotetText ? (
                    <p className="caseStudyList__heading">{promotetText}</p>
                  ) : null}
                  {link ? (
                    <ButtonStyled dark={true}>
                      <Link to={link}>{linkText}</Link>
                    </ButtonStyled>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </CaseStudyListStyled>
    </VisibilitySensor>
  )
}

export default CaseStudyList
